<template lang="">
  <IAmOverlay :loading="loading || (!isEmptyList && !tourList.length )">
    <BAlert
      v-if="isEmptyList"
      show
      variant="warning"
      class="px-2 py-1"
    >
      Không tìm thấy chương trình tour nào !
    </BAlert>
    <BAlert
      v-else-if="tourList.length === 0"
      show
      variant="warning"
      class="px-2 py-1"
    >
      <i>Đang tải dữ liệu...</i>
    </BAlert>
    <Stack
      v-else
      class="gap-3"
    >
      <TourListCard
        v-for="(tourItem, tourIndex) in tourList"
        :key="tourItem?.id + '_' + tourIndex"
        :tourData="tourItem"
        :isShowCommission="isShowCommission"
      />

      <infinite-loading @infinite="infiniteHandler">
        <div
          v-if="!tourList.length"
          slot="spinner"
        />
        <div slot="no-more">
          <strong class="font-italic">Đã hiển thị toàn bộ Tour</strong>
        </div>
        <div slot="no-results">
          <strong class="font-italic">Đã hiển thị toàn bộ Tour</strong>
        </div>
      </infinite-loading>
    </Stack>
  </IAmOverlay>
</template>
<script>
import { BAlert } from 'bootstrap-vue'
import InfiniteLoading from 'vue-infinite-loading'

import TourListCard from './TourListCard.vue'

export default {
  components: {
    TourListCard,
    BAlert,
    InfiniteLoading,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  props: {
    tourList: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pulling: {
      type: Boolean,
      default: false,
    },
    isEmptyList: {
      type: Boolean,
      default: false,
    },
    isShowCommission: {
      type: Boolean,
      default: true,
    },
    infiniteHandler: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    return {}
  },
}
</script>
<style lang="">

</style>
