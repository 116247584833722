<template>
  <b-card
    id="filters-tour"
    body-class="px-1 py-0"
    class="mb-0"
    header-class="py-1"
  >
    <template #header>
      <div
        class="w-100 d-flex-between cursor-pointer"
        @click="handleToggleShow"
      >
        <div class="d-flex-center justify-content-start gap-2">
          <IAmIcon
            class="text-success"
            icon="filter"
            size="18"
          />
          <span class="fw-600 text-nowrap">{{ $t('flight.filters') }}</span>
        </div>

        <div class="d-flex-center">
          <b-button
            variant="outline-danger"
            :disabled="!isFiltering"
            class="rounded-lg px-1 py-50 mr-1"
            @click.stop="handleClearFilter"
          >
            <span class="font-weight-bolder">{{ $t('flight.clearAllFilters') }}</span>
          </b-button>
          <IAmIcon
            icon="chevronDownOutline"
            size="18"
            :style="{ transform: rotateStyleVisible, width: '12px' }"
            class="rotate_class text-secondary cursor-pointer"
          />
        </div>
      </div>
    </template>

    <!-- SECTION collapse Filter -->
    <b-collapse
      v-model="isVisible"
      tag="div"
    >
      <div class="d-flex flex-column gap-2 pb-1">
        <!-- ANCHOR: Tour type -->
        <div class="d-flex flex-column gap-2">
          <div class="divider-line" />
          <div class="fw-600">
            Dòng tour
          </div>
          <div class="d-flex-center gap-3">
            <b-button
              v-for="tourType of tourTypes"
              :key="tourType"
              :variant="tourType === tourTypeFilter ? 'success' : 'outline-dark'"
              class="rounded-lg px-1 py-75 flex-1"
              @click="$emit('update:tourTypeFilter', tourType)"
            >
              <span class="font-weight-bolder">
                {{ $t(`tour.tourType.${tourType}`) }}
              </span>
            </b-button>
          </div>
        </div>

        <!-- ANCHOR: category / diem den -->
        <div class="d-flex flex-column gap-2">
          <div class="divider-line" />
          <div class="fw-600">
            Điểm đến
          </div>
          <div class="d-flex-center gap-3">
            <v-select
              :value="categoryFilter"
              label="name"
              :options="categoryOptions?.[tourTypeFilter]"
              :filterable="false"
              searchable
              class="w-100"
              :reduce="val => val"
              :placeholder="'Chọn điểm đến'"
              :clearable="false"
              @input="val => $emit('update:categoryFilter', val)"
            >
              <template #option="data">
                {{ data.name }}
              </template>

              <template #selected-option="data">
                {{ data.name }}
              </template>

              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </div>
        </div>

        <!-- ANCHOR: thang -->
        <div class="d-flex flex-column gap-2">
          <div class="divider-line" />
          <div class="fw-600">
            Tháng
          </div>
          <div class="d-flex-center gap-3">
            <v-select
              :value="monthFilter"
              :options="[1,2,3,4,5,6,7,8,9,10,11,12]"
              :filterable="false"
              searchable
              class="w-100"
              :reduce="val => val"
              :placeholder="'Chọn tháng'"
              :clearable="true"
              @input="val => $emit('update:monthFilter', val)"
            >
              <template #option="data">
                Tháng {{ data.label }}
              </template>

              <template #selected-option="data">
                Tháng {{ data.label }}
              </template>
            </v-select>
          </div>
        </div>

        <!-- ANCHOR: Phương tiện -->
        <div class="d-flex flex-column gap-2">
          <div class="divider-line" />
          <div class="fw-600">
            Phương tiện
          </div>
          <div class="d-flex-center gap-3">
            <v-select
              :value="vehicleFilter"
              :options="VEHICLE_TYPES"
              searchable
              class="w-100"
              label="label"
              :reduce="val => val"
              :placeholder="'Chọn phương tiện'"
              :clearable="true"
              @input="val => $emit('update:vehicleFilter', val)"
            >
              <template #option="data">
                {{ $t(`tour.vehiclesType.${data.label}`) }}
              </template>

              <template #selected-option="data">
                {{ $t(`tour.vehiclesType.${data.label}`) }}
              </template>
            </v-select>
          </div>
        </div>

        <!-- ANCHOR: Ngân sách -->
        <div class="d-flex flex-column gap-2">
          <div class="divider-line" />
          <div class="fw-600">
            Ngân sách
          </div>
          <div class="d-grid grid-cols-2 rows-min-content gap-8px">
            <BButton
              v-for="(priceButton, priceButtonIndex) of priceButtonsOptions"
              :key="priceButtonIndex"
              :variant="priceButton.name === priceButtonFilter?.name ? 'success' : 'outline-dark'"
              :class="`px-1 py-75 flex-1 not-focus-visible ${priceButton.name === priceButtonFilter?.name ? 'active' : ''}`"
              @click="onClickPriceButtonHandle(priceButton)"
            >
              <span class="fw-600">{{ priceButton.name }}</span>
            </BButton>
          </div>
        </div>
      </div>
    </b-collapse>
    <!-- !SECTION -->
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCollapse,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'

import { tourTypes, VEHICLE_TYPES } from '../useTourHandle'

export default {
  components: {
    BButton,
    BCard,
    BCollapse,
    vSelect: () => import('vue-select'),
  },
  props: {
    tourTypeFilter: {
      type: String,
      default: tourTypes.DOMESTIC,
    },
    categoryFilter: {
      type: [Object, null],
      default: null,
    },
    categoryOptions: {
      type: Object,
      default: () => ({}),
    },
    priceButtonsOptions: {
      type: Array,
      default: () => ([]),
    },
    priceButtonFilter: {
      type: [Object, null],
      default: null,
    },
    monthFilter: {
      type: [Number, null],
      default: null,
    },
    vehicleFilter: {
      type: [String, null],
      default: null,
    },
    isFiltering: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isVisible = ref(true)
    const rotateStyleVisible = computed(() => isVisible.value ? 'rotate(0deg)' : 'rotate(-180deg)')
    const handleToggleShow = () => {
      isVisible.value = !isVisible.value
    }

    const handleClearFilter = () => {
      emit('clearFilters')
    }

    const onClickPriceButtonHandle = priceButton => {
      if (props.priceButtonFilter?.name === priceButton?.name) {
        emit('update:priceButtonFilter', null)
        return
      }
      emit('update:priceButtonFilter', priceButton)
    }

    return {
      isVisible,
      rotateStyleVisible,
      handleToggleShow,
      tourTypes,
      handleClearFilter,
      VEHICLE_TYPES,
      onClickPriceButtonHandle,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
#sort-select ::v-deep {
  .vs__dropdown-toggle {
    border: none;
  }
}

.inputFilter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  @media (max-width: 1536px) {
    flex-direction: column;
  }
}

.rotate_class {
  transition: transform 0.3s ease;
}

.divider-line {
  padding: 2px auto;
  border-top: 2px solid #e6e6e6;
}

.d-grid {
  display: grid;
}
.grid-cols-2 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content;
}

.not-focus-visible {
  &:not(.active):focus {
    background: transparent !important;
  }
}
</style>
