<template lang="">
  <div class="p-1">
    <TourListHeader />
    <div class="d-flex justify-content-between flex-column flex-md-row gap-3">
      <div :class="`fw-900 font-large ${isMobileView ? 'text-30px':'text-40px'} text-tour`">
        <div>Tour Du lịch</div>
        <HStack
          ref="filterMobileRef"
          between
          class="with-yellow-div"
        >
          <div>{{ mamaFullName }}</div>
          <div v-if="isMobileView">
            <BButton
              v-b-toggle.tour-list-sidebar
              variant="success d-flex-center gap-1 p-50"
              size="sm"
            >
              <IAmIcon
                class="text-white"
                icon="filter"
                size="18"
              />
              <span class="fw-600 text-nowrap">{{ $t('flight.filters') }}</span>
            </BButton>
          </div>
        </HStack>
      </div>
      <div class="d-flex flex-column align-items-end justify-content-end gap-2">
        <div v-if="totalTours || isEmptyList">
          <BSpinner
            v-if="pulling"
            small
            variant="primary"
          />
          Chúng tôi tìm thấy <span class="fw-700 text-18px">{{ totalTours }}</span> chương trình tour cho Quý khách.
        </div>
        <div
          v-else
          class="d-flex-center gap-2"
        >
          <BSpinner
            small
            variant="primary"
          />
          Chúng tôi đang tìm kiếm chương trình tour cho Quý khách.
        </div>

        <HStack class="gap-2">
          <BButton
            v-for="(sortButton, sortButtonIndex) in sortButtonOptions"
            :key="sortButtonIndex"
            :disabled="loading || pulling"
            :variant="sortButtonFilter?.name === sortButton.name ? 'success' : 'outline-dark'"
            :class="`p-50 ${loading || pulling ? 'cursor-not-allowed' : ''}`"
            size="sm"
            @click="handleClickSortButton(sortButton)"
          >
            {{ sortButton?.name }}
          </BButton>
        </HStack>
      </div>
    </div>

    <Transition
      v-if="isMobileView && showStickyFilterButton"
      name="fade"
      mode="out-in"
    >
      <div
        class="position-fixed"
        style="top: 55px; right: 1rem; z-index: 10;"
      >
        <BButton
          v-b-toggle.tour-list-sidebar
          variant="success d-flex-center gap-1 p-50"
          size="sm"
        >
          <IAmIcon
            class="text-white"
            icon="filter"
            size="18"
          />
          <span class="fw-600 text-nowrap">{{ $t('flight.filters') }}</span>
        </BButton>
      </div>
    </Transition>

    <b-row class="mt-1">
      <b-col
        cols="12"
        md="9"
      >
        <TourListResults
          :tourList="tourList"
          :loading="loading"
          :pulling="pulling"
          :isEmptyList="isEmptyList"
          :isShowCommission="isShowCommission"
          :infiniteHandler="infiniteHandler"
        />
      </b-col>
      <b-col
        v-if="!isMobileView"
        cols="12"
        md="3"
        class="pl-md-0"
      >
        <div class="right-side-sticky">
          <IAmOverlay :loading="loading || (!isEmptyList && !tourList.length )">
            <Stack class="gap-3">
              <TourListFilters
                :tourTypeFilter.sync="tourTypeFilter"
                :categoryFilter.sync="categoryFilter"
                :monthFilter.sync="monthFilter"
                :vehicleFilter.sync="vehicleFilter"
                :priceButtonFilter.sync="priceButtonFilter"
                :priceButtonsOptions="priceButtonsOptions"
                :categoryOptions="categoryOptions"
                :isFiltering="isFiltering"
                @clearFilters="clearFilters"
              />
              <TourListDisplaySettings />
            </Stack>
          </IAmOverlay>
        </div>
      </b-col>
    </b-row>

    <TourListSideBar v-if="isMobileView">
      <IAmOverlay :loading="loading || (!isEmptyList && !tourList.length )">
        <Stack class="gap-3">
          <TourListFilters
            :tourTypeFilter.sync="tourTypeFilter"
            :categoryFilter.sync="categoryFilter"
            :monthFilter.sync="monthFilter"
            :vehicleFilter.sync="vehicleFilter"
            :priceButtonFilter.sync="priceButtonFilter"
            :priceButtonsOptions="priceButtonsOptions"
            :categoryOptions="categoryOptions"
            :isFiltering="isFiltering"
            @clearFilters="clearFilters"
          />
          <TourListDisplaySettings />
        </Stack>
      </IAmOverlay>
    </TourListSideBar>
  </div>
</template>
<script>
import {
  BButton, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import { computed, onUnmounted, ref } from '@vue/composition-api'
import { useWindowScroll } from '@vueuse/core'

import env from '@/libs/env'
import store from '@/store'

import TourListFilters from './TourListFilters.vue'
import TourListResults from './TourListResults.vue'
import useTourListHandle from './useTourListHandle'
import TourListDisplaySettings from '../components/TourListDisplaySettings.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BSpinner,
    TourListHeader: () => import('./TourListHeader.vue'),
    TourListFilters,
    TourListResults,
    TourListDisplaySettings,
    TourListSideBar: () => import('./TourListSideBar.vue'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  setup() {
    const {
      loading,
      pulling,
      tourTypeFilter,
      monthFilter,
      startDateFilter,
      categoryFilter,
      tourList,
      totalTours,
      featureInDevelopment,
      categoryOptions,
      isEmptyList,
      clearIntervals,
      clearFilters,
      isFiltering,
      infiniteHandler,
      vehicleFilter,
      priceButtonsOptions,
      priceButtonFilter,
      sortButtonFilter,
      sortButtonOptions,
    } = useTourListHandle()

    const isShowCommission = computed(() => store.getters['app-tour/getShowCommission'])

    onUnmounted(() => {
      clearIntervals()
    })

    function handleClickSortButton(sortButton) {
      sortButtonFilter.value = sortButton
    }

    const filterMobileRef = ref()
    const { y } = useWindowScroll()
    const showStickyFilterButton = computed(() => y.value > filterMobileRef.value?.$el?.offsetTop)

    return {
      tourList,
      mamaFullName: env.mamaFullName,
      loading,
      pulling,
      totalTours,
      featureInDevelopment,
      tourTypeFilter,
      monthFilter,
      startDateFilter,
      categoryOptions,
      categoryFilter,
      isEmptyList,
      isShowCommission,
      clearFilters,
      isFiltering,
      infiniteHandler,
      vehicleFilter,
      priceButtonsOptions,
      priceButtonFilter,
      sortButtonFilter,
      sortButtonOptions,
      handleClickSortButton,
      showStickyFilterButton,
      filterMobileRef,
    }
  },
}
</script>
<style lang="scss" scoped>
.with-yellow-div {
  position: relative;
  &::before {
    content: '';
    background-color: #EFD702;
    z-index: -1;
    position: absolute;
    top: 50%;
    left: -10%;
    width: 60%;
    height: 60%;
  }
}
.right-side-sticky {
  position: sticky;
  position: -webkit-sticky; /* Safari */
  top: 55px;
  right: 0;
  max-height: calc(100vh - 55px);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
