var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading || !_vm.isEmptyList && !_vm.tourList.length
    }
  }, [_vm.isEmptyList ? _c('BAlert', {
    staticClass: "px-2 py-1",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Không tìm thấy chương trình tour nào ! ")]) : _vm.tourList.length === 0 ? _c('BAlert', {
    staticClass: "px-2 py-1",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('i', [_vm._v("Đang tải dữ liệu...")])]) : _c('Stack', {
    staticClass: "gap-3"
  }, [_vm._l(_vm.tourList, function (tourItem, tourIndex) {
    return _c('TourListCard', {
      key: (tourItem === null || tourItem === void 0 ? void 0 : tourItem.id) + '_' + tourIndex,
      attrs: {
        "tourData": tourItem,
        "isShowCommission": _vm.isShowCommission
      }
    });
  }), _c('infinite-loading', {
    on: {
      "infinite": _vm.infiniteHandler
    }
  }, [!_vm.tourList.length ? _c('div', {
    attrs: {
      "slot": "spinner"
    },
    slot: "spinner"
  }) : _vm._e(), _c('div', {
    attrs: {
      "slot": "no-more"
    },
    slot: "no-more"
  }, [_c('strong', {
    staticClass: "font-italic"
  }, [_vm._v("Đã hiển thị toàn bộ Tour")])]), _c('div', {
    attrs: {
      "slot": "no-results"
    },
    slot: "no-results"
  }, [_c('strong', {
    staticClass: "font-italic"
  }, [_vm._v("Đã hiển thị toàn bộ Tour")])])])], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }