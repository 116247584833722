import { computed, ref, watch } from '@vue/composition-api'

import store from '@/store'

import useToast from '@useToast'

import tourStoreModule from '../tourStoreModule'
import { tourTypes } from '../useTourHandle'

export default function useTourListHandle() {
  if (!store.hasModule('app-tour')) {
    store.registerModule('app-tour', tourStoreModule)
  }
  const { toastError, toastWarning } = useToast()

  const loading = ref(false)

  const tourList = ref([])
  const totalTours = ref(null)
  const pulling = ref(false)

  const page = ref(1)
  const sizePerPage = ref(10)

  const sortButtonOptions = ref([
    { name: 'Ngày khởi hành gần nhất', sortBy: 'startDate', sortDirection: 'ASC' },
    { name: 'Giá từ thấp đến cao', sortBy: 'adultsPriceSales', sortDirection: 'ASC' },
    { name: 'Giá từ cao đến thấp', sortBy: 'adultsPriceSales', sortDirection: 'DESC' },
  ])

  const sortButtonFilter = ref(sortButtonOptions.value[0])

  const isEmptyList = ref(false)
  const PULLING_INTERVAL = 1000
  const UNDERGROUND_PULLING_INTERVAL = 5000

  // PARAMS TO SEARCH
  const tourTypeFilter = ref(tourTypes.OUTBOUND) // DOMESTIC / OUTBOUND
  const monthFilter = ref(null) // DOMESTIC / OUTBOUND
  const startDateFilter = ref('') // DOMESTIC / OUTBOUND
  const categoryFilter = ref(null)
  const vehicleFilter = ref(null)
  const priceButtonFilter = ref(null)

  function clearFilters() {
    monthFilter.value = null
    startDateFilter.value = null
    vehicleFilter.value = null
    priceButtonFilter.value = null
    sortButtonFilter.value = sortButtonOptions.value[0]
  }

  const isFiltering = computed(() => Boolean(monthFilter.value
    || startDateFilter.value || vehicleFilter.value || priceButtonFilter.value))

  const categoryOptions = ref({
    DOMESTIC: [],
    OUTBOUND: [],
  })

  const priceButtonsOptions = ref([
    {
      minPrice: 0,
      maxPrice: 5000000,
      name: 'Dưới 5 triệu',
    },
    {
      minPrice: 5000000,
      maxPrice: 10000000,
      name: 'Từ 5 - 10 triệu',
    },
    {
      minPrice: 10000000,
      maxPrice: 20000000,
      name: 'Từ 10 - 20 triệu',
    },
    {
      minPrice: 20000000,
      maxPrice: null,
      name: 'Trên 20 triệu',
    },
  ])
  let intervalID = null
  let undergroundIntervalID = null

  function clearIntervals() {
    if (intervalID) {
      clearInterval(intervalID)
      intervalID = null
    }
    if (undergroundIntervalID) {
      clearInterval(undergroundIntervalID)
      undergroundIntervalID = null
    }
  }

  async function infiniteHandler($state) {
    if (tourList.value.length >= totalTours.value && !pulling.value) {
      $state.complete()
      return
    }
    page.value += 1
    const params = {
      type: tourTypeFilter.value,
      month: monthFilter.value ? Number(monthFilter.value) : undefined,
      startDate: startDateFilter.value || undefined,
      categoryId: categoryFilter.value?.id || undefined,
      page: page.value,
      size: sizePerPage.value,
      sortBy: sortButtonFilter.value?.sortBy ?? 'startDate',
      sortDirection: sortButtonFilter.value?.sortDirection ?? 'ASC',
      vehicle: vehicleFilter.value || undefined,
      minPrice: priceButtonFilter.value?.minPrice ? priceButtonFilter.value?.minPrice : undefined,
      maxPrice: priceButtonFilter.value?.maxPrice ? priceButtonFilter.value?.maxPrice : undefined,
    }
    if (tourList.value.length) {
      try {
        const response = await store.dispatch('app-tour/getTourRow', params)
        tourList.value.push(...response.data.items)
        $state.loaded()
        if (response.data.items.length === 0) {
          $state.complete()
        }
      } catch (error) {
        console.error({ error })
        $state.error()
      }
    }
  }

  const fetchTourList = () => {
    // console.log('=>> start fetch')
    // categoryId is required
    if (!categoryFilter.value?.id) {
      return
    }
    isEmptyList.value = false
    pulling.value = true
    loading.value = true
    tourList.value = []
    totalTours.value = null
    page.value = 1
    const params = {
      type: tourTypeFilter.value,
      month: monthFilter.value ? Number(monthFilter.value) : undefined,
      startDate: startDateFilter.value || undefined,
      categoryId: categoryFilter.value?.id || undefined,
      page: page.value,
      size: sizePerPage.value,
      sortBy: sortButtonFilter.value?.sortBy ?? 'startDate',
      sortDirection: sortButtonFilter.value?.sortDirection ?? 'ASC',
      vehicle: vehicleFilter.value || undefined,
      minPrice: priceButtonFilter.value?.minPrice ? priceButtonFilter.value?.minPrice : undefined,
      maxPrice: priceButtonFilter.value?.maxPrice ? priceButtonFilter.value?.maxPrice : undefined,
    }
    const intervalID = setInterval(async () => {
      // console.log('call, pulling =', pulling.value)
      if (!pulling.value) return

      try {
        const res = await store.dispatch('app-tour/getTourRow', params)
        tourList.value = res.data.items
        totalTours.value = res.data.total
        pulling.value = res.data.pulling
        if (res.data.items.length) {
          // console.log('end call')
          clearInterval(intervalID)
          if (pulling.value) {
            // console.log('=>> start underground call')
            const undergroundIntervalID = setInterval(async () => {
              // console.log('underground call')
              try {
                const undergroundResponse = await store.dispatch('app-tour/getTourRow', params)
                // tourList.value = undergroundResponse.data.items
                totalTours.value = undergroundResponse.data.total
                pulling.value = undergroundResponse.data.pulling
                if (!pulling.value) {
                  // console.log('end underground call')
                  clearInterval(undergroundIntervalID)
                }
              } catch (error) {
                clearInterval(undergroundIntervalID)
              }
            }, UNDERGROUND_PULLING_INTERVAL)
          }
        } else if (!pulling.value) {
          isEmptyList.value = true
          // console.log('end call')
          clearInterval(intervalID)
        }
      } catch (error) {
        toastError({
          title: 'messagesList.error',
          content: `${error?.message ?? error}`,
        })
        clearInterval(intervalID)
      } finally {
        loading.value = false
      }
    }, PULLING_INTERVAL)
  }

  const loadingCategory = ref(false)
  const fetchTourCategory = type => new Promise((resolve, reject) => {
    loadingCategory.value = true
    const params = {
      type,
    }
    store
      .dispatch('app-tour/getTourCategory', params)
      .then(res => {
        resolve(res.data)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        reject(e)
      })
      .finally(() => {
        loadingCategory.value = false
      })
  })

  watch(tourTypeFilter, async (newValue, oldValue) => {
    if (categoryFilter.value && oldValue && newValue !== oldValue) {
      categoryFilter.value = null
    }
    if (!categoryOptions.value?.[tourTypeFilter.value]?.length) {
      const resCategory = await fetchTourCategory(tourTypeFilter.value)
      categoryOptions.value[tourTypeFilter.value] = resCategory.items
    }
    categoryFilter.value = categoryOptions.value[tourTypeFilter.value]?.[0] ?? null
  }, { immediate: true })

  function refetchData() {
    fetchTourList()
  }

  watch([startDateFilter, monthFilter, categoryFilter, vehicleFilter, priceButtonFilter, sortButtonFilter], () => {
    refetchData()
  }, { deep: true })

  function featureInDevelopment() {
    toastWarning('featureInDevelopment')
  }
  return {
    loading,
    pulling,
    isEmptyList,
    fetchTourList,
    refetchData,
    totalTours,
    tourList,
    featureInDevelopment,
    tourTypeFilter,
    categoryFilter,
    categoryOptions,
    monthFilter,
    clearIntervals,
    clearFilters,
    isFiltering,
    infiniteHandler,
    vehicleFilter,
    priceButtonsOptions,
    priceButtonFilter,
    sortButtonOptions,
    sortButtonFilter,
  }
}
